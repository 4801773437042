import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import { Link } from "gatsby"

const PageNotFound = () => {

  return (
    <Layout siteTitle="404 - Page Not Found">
       <section id="not-found-section"> 
            <div className="container"><Navigation /></div>
              <div className="container">
                  <div className="not-found-wrapper">
                          <div className="error-code">404</div>
                          <div className="error-label">Oops!<br/> We can't find the page you are looking for.</div>
                          <Link to="/" className="go-home-btn">
                                <div className="btn-container">
                                  <div className="btn-content">Go Back Home</div>
                                </div>
                          </Link>
                  </div>
              </div>
       </section>


       <section id="contact">
          <div className="container">
              <div className="columns">
                  <div className="column is-6 contact-info-grp">
                      <div className="tile is-ancestor is-vertical">
                          <div className="tile contact-title">Our Offices</div>
                          <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                          <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br/> Torrance CA, 90505</div>
                          <a href="tel:310-325-1198" className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                      </div>

                  </div>
                  <div className="column is-6 contact-map">
                      <StaticImage alt="Location Map for Beyond Women's Health" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                  </div>
              </div>
          </div>
       </section>
    </Layout>
  )
}

export default PageNotFound